// requires additional assets to work
// <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">
// <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript"></script>
import { ready } from "#js/components/utils"
import { sendAlgoliaConversionEvent } from "#js/integrations/algolia"
import { addTrackingUrlParameters } from "#js/components/utm"

ready(function () {
  // Integrate Calendly widget via "data-calendly-url" attribute
  document.querySelectorAll("[data-calendly-url]").forEach(function (element) {
    element.addEventListener("click", (event) => {
      event.preventDefault()
      globalThis.Calendly.initPopupWidget({
        url: element.dataset.calendlyUrl,
        prefill: {
          name: element.dataset.calendlyName ? element.dataset.calendlyName : "",
          email: element.dataset.calendlyEmail ? element.dataset.calendlyEmail : "",
        },
      })
      return false
    })

    // we do get the same event 3 times, but only need 1
    globalThis.calendlySuccessLoading = false
    globalThis.calendlyDateAndTimeSelected = false

    globalThis.addEventListener("message", (event) => {
      if (
        !globalThis.calendlyDateAndTimeSelected &&
        event.data.event === "calendly.date_and_time_selected"
      ) {
        globalThis.dataLayer.push({
          event: "expert_begin_checkout",
        })
        globalThis.calendlyDateAndTimeSelected = true
      }

      if (
        !globalThis.calendlySuccessLoading &&
        event.data.event === "calendly.event_scheduled"
      ) {
        if (element.dataset.calendlySuccessUrl) {
          globalThis.calendlySuccessLoading = true
          const data = new FormData()
          data.append(
            "csrfmiddlewaretoken",
            document.querySelector("input[name=csrfmiddlewaretoken]").value,
          )
          fetch(addTrackingUrlParameters(element.dataset.calendlySuccessUrl), {
            method: "POST",
            body: data,
            credentials: "same-origin",
          }).then((response) => {
            globalThis.calendlySuccessLoading = false
            return response.json()
          }).then(function (data) {
            globalThis.dataLayer.push({
              event: "expert_purchase",
              expertBookingTransactionId: data.expert_booking_id,
            })
            sendAlgoliaConversionEvent(document.getElementById("algolia-conversion"))
            globalThis.location.reload()
          }).catch((error) => {
            globalThis.calendlySuccessLoading = false
            console.error(error)
          })
        }
      }
    })
  })
})
